.printOnly {
  display: none;
}

@media print {
  .sidebar, .topbar, .sidebar-mobile {
    display: none !important;
  }
  .printOnly {
    display: flex !important;
  }
  .mainBoxContent {
    overflow-x: hidden;
  }
  .printNot {
    display: none !important;
   }
   .schedulePrintRow {
    flex-direction: row !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .schedulePrintColumn {
     flex-direction: column !important;
     margin-left: 15px !important;
   }
   .name-column--cell {
    color: black !important;
   }
   .printWithFlex {
    color: black !important;
    font-size: 12px !important;
   }
   .dgHeaderPrint {
    border: none !important;
    font-size: 10px !important;
    font-weight: bolder !important;

    color: black !important; 
   } 
   .MuiDataGrid-iconSeparator {
    display: none !important;
   }
   .css-t89xny-MuiDataGrid-columnHeaderTitle {
    overflow: visible !important;
   }
}
/* .pro-menu-item a.active {
  background-color: green !important;
}
.nav-member .react-slidedown .pro-menu-item a.active {
  background-color: blue !important;
  font-weight: 500;
} */

/* for Mobile View */
@media (max-width: 900px) {
  .sidebar, .topbar {
    display: none !important;
  }
  .mainBoxContent{
    margin-top: 30px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    max-width: 99% !important;
    overflow-x: scroll;
    /* position: absolute; */
  }
  .desktopOnly {
    display: none !important;
  }
}
@media (max-width: 450px) {
  .mobileHidden {
    display: none !important;
  }
}

/* for Desktop View */
@media (min-width: 900px) {
  .mainBoxContent{
    padding-left: 10%;
    /* position: relative; */
  }
  .tabAndMobOnly {
    display: none !important;
  }
}

.myBold {
  font-weight: 600;
  color: honeydew;
}
.react-datepicker-wrapper {
  display: flex;
  flex: 1;
}
