/* .dataGridTitle {
    display: none;
    color: black;
} */
.subtitle {
    font-size: 16px;
    margin-top: -15px;
}
@media print {
   .printNot {
    display: none !important;
   }
   .printMe {
    display: flex;
   }
   .dataGridTitle {
    color: black !important;
    
   }
}
.normalLineHeight {
    line-height: normal;
    height: 200px;
}
input[name="startDate"] {
    color: white; 
    background-color: #293040;
    padding: 5px; 
}
input[name="paymentDate"] {
    color: white; 
    background-color: #293040;
    padding: 5px; 
}
.newLoanBox {
    padding: 20px;
    border: 1px solid rgb(26, 95, 74);
    margin-bottom: 30px;
    margin-top: 5px;
}
