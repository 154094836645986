
  /* Sidebar content should have a fixed height and be scrollable */
  /* .ps-sidebar-container{
    width: 250px;
  } */
  .sidebar{
    top: 0;
    position: fixed;
    height: 100vh;
    padding-bottom: 40px;
    z-index: 100;
    /* display: inline-flex; */
  }

  .ps-sidebar-root {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: visible;
  }
  .css-dip3t8 {
    overflow-x: unset !important;
    overflow-y: unset !important;
  }
  .css-1dp3zeq{
    max-width: 210px;
    width: unset;
    min-width: unset;
  }
  #root > div:nth-child(2) > div.sidebar.MuiBox-root.css-1pin7o1 > aside {
    max-width: 210px !important;
  }
  